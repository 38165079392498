import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { PageWidgetSpec, Widget } from "../../";
import { amenityCategoryMultiSelect, getDateFormat, getSelectedAmenities, getSubjectsList, resortAutocomplete } from "../../../components/utils";
import { distributionChannelOptions, getMxtsEnv } from "../";

import { DATE_FORMAT } from "../../../utils/constants";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { NumberMultiSelectOption } from "../selectOption.types";
import { SearchFacetContainer } from "./SearchFacetContainer";
import { WidgetGroup } from "../../widget.enum";
import { autocompleteSiteSpec } from "../../../form-specs/models/autocompleteSite";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";
import { pageSpec } from "../../../form-specs/models";

export interface WidgetOptions {
    localizedContent: LocaleContent[];
    styleIds: any[];
    distributionChannelId: number | null;
    dateRangePicker: boolean;
    multiAccoKindSelector: boolean;
    multiResortSelector: boolean;
    showDateStayPopup: boolean;
    facetTitle: string;
    redirectBookingsEngine: boolean;
    resortId?: string;
    useResortForSubjects?: boolean;
    selectedAmenityCategories: NumberMultiSelectOption[];
    selectDefaultSubject?: boolean;
    defaultSubject?: number;
    nrOfDefaultSubjects?: number;
    showStartEndDateLabel: boolean;
    dateFormat: string;
}

export interface LocaleContent {
    facetTitle: string;
    locale: string;
    baseUrlPageId: string | null;
    baseUrlSiteId: string | null;
}

const TARGETS = ["searchfacet"];

/* jscpd:ignore-start */
const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "searchfacet-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetSearchfacet, "searchfacetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetSearchfacet, "searchfacetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.pluginForm, "general"),
                    properties: [
                        [
                            {
                                variable: "facetTitle",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "facetTitle"),
                                type: "text",
                            },
                            {
                                variable: "distributionChannelId",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "distributionChannel"),
                                type: "select",
                                optionList: () => distributionChannelOptions(MxtsApi),
                            },
                            {
                                variable: "useResortForSubjects",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "useResortForSubject"),
                                type: "checkbox",
                            },
                            resortAutocomplete("resortId", undefined, true),
                            {
                                variable: "selectDefaultSubject",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "selectDefaultSubject"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "defaultSubject",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "defaultSubject"),
                                type: "autocomplete",
                                isClearable: false,
                                options: () => getSubjectsList(MxtsApi),
                                visible: (options: WidgetOptions) => !!options.selectDefaultSubject,
                            },
                            {
                                variable: "nrOfDefaultSubjects",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "nrOfDefaultSubjects"),
                                type: "number",
                                visible: (options: WidgetOptions) => !!options.selectDefaultSubject,
                            },
                            amenityCategoryMultiSelect("selectedAmenityCategories"),
                            {
                                variable: "redirectBookingsEngine",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "redirectBookingsEngine"),
                                type: "checkbox",
                            },
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "facetTitle",
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "facetTitle"),
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "objectPageSiteId"),
                                        variable: "baseUrlSiteId",
                                        type: "autocomplete",
                                        refType: autocompleteSiteSpec,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "objectPagePageId"),
                                        variable: "baseUrlPageId",
                                        type: "autocomplete",
                                        refType: pageSpec,
                                        dependsOnSiteSpec: "baseUrlSiteId",
                                    },
                                ],
                            }),
                            {
                                variable: "dateRangePicker",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "dateRangePicker"),
                                type: "checkbox",
                            },
                            ...getDateFormat("dateFormat"),
                            {
                                variable: "showStartEndDateLabel",
                                label: getI18nLocaleObject(namespaceList.admin, "showStartEndDateLabel"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.dateRangePicker,
                            },
                            {
                                variable: "multiAccoKindSelector",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "multiAccoKindSelector"),
                                type: "checkbox",
                            },
                            {
                                variable: "multiResortSelector",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "multiResortSelector"),
                                type: "checkbox",
                            },
                            {
                                variable: "showDateStayPopup",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "showDateStayPopup"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
            ],
        },
    ],
};
/* jscpd:ignore-end */
export const searchFacetWidget: PageWidgetSpec<WidgetOptions> = {
    id: "searchfacet",
    type: "page",
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
    name: getI18nLocaleObject(namespaceList.widgetSearchfacet, "searchfacet"),
    description: getI18nLocaleObject(namespaceList.widgetSearchfacet, "searchfacetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        localizedContent: [],
        distributionChannelId: null,
        styleIds: [],
        dateRangePicker: false,
        multiAccoKindSelector: true,
        multiResortSelector: false,
        showDateStayPopup: true,
        facetTitle: "",
        redirectBookingsEngine: false,
        selectedAmenityCategories: [],
        showStartEndDateLabel: false,
        dateFormat: DATE_FORMAT.DISPLAY,
    }),
    async render(widget: Widget<WidgetOptions>, context) {
        // If a category is specified, then dont get all amenities by placing a request
        const amenityCategories =
            widget.options.selectedAmenityCategories && widget.options.selectedAmenityCategories.length > 0
                ? await getSelectedAmenities(context.mxtsApi, widget.options.selectedAmenityCategories, undefined, await getMxtsEnv(context, context.currentLocale.code))
                : [];

        return <SearchFacetContainer options={widget.options} context={context} selectedAmenityCategories={amenityCategories} />;
    },
};
