import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";

import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { SearchFacet } from "./SearchFacet";
import { WidgetOptions } from "./";
import { getBaseFilter } from "../../../utils/availability.util";
import { pageLink } from "../../../routing";
import { parse } from "query-string";

interface SearchFacetContainerProps {
    options: WidgetOptions;
    context: CMSProviderProperties;
    className?: string;
    selectedAmenityCategories: MXTS.AmenityCategory[] | null;
}

interface SearchFacetContainerState {
    preSelectedAmenities: number[] | null;
    baseUri: string;
}

export class SearchFacetContainer extends React.Component<SearchFacetContainerProps, SearchFacetContainerState> {
    constructor(props: SearchFacetContainerProps) {
        super(props);
        this.state = {
            baseUri: "",
            preSelectedAmenities: [],
        };
    }

    public componentDidMount() {
        const { context, options } = this.props;
        const localContent = options && options.localizedContent ? options.localizedContent.find((lc) => lc.locale === context.currentLocale.locale) : null;
        if (localContent?.baseUrlSiteId) {
            context.cmsApi.siteApi.findById({ id: localContent.baseUrlSiteId, projection: { sitemap: 0 } }).then(async (site) => {
                if (site && localContent.baseUrlPageId) {
                    const baseUri = (await pageLink({ site, pageId: localContent.baseUrlPageId, locale: context.currentLocale, context })) || "";
                    this.setState({ baseUri });
                }
            });
        }

        // If Resorts are already selected in widget options, only fetch accommodations for the current list of Resorts
        if (this.props.selectedAmenityCategories && this.props.selectedAmenityCategories.length > 0) {
            const amenitiesIds: number[] = [];
            this.props.selectedAmenityCategories!.forEach((category) => {
                if (category) {
                    amenitiesIds.push(...category.amenities!.map((amenity) => amenity.amenityId));
                }
            });
            this.setState({ preSelectedAmenities: amenitiesIds });
        }
    }

    public render(): JSX.Element | null {
        const { className, context, options } = this.props;
        const { baseUri, preSelectedAmenities } = this.state;
        let baseFilter: MXTS.AvailabilityRequest | undefined;
        const localContent = options && options.localizedContent ? options.localizedContent.find((lc) => lc.locale === context.currentLocale.locale) : null;
        const { selectDefaultSubject, defaultSubject, nrOfDefaultSubjects } = options;
        let defaultSelectedSubject: Map<number, number> | undefined;
        if (selectDefaultSubject && defaultSubject && nrOfDefaultSubjects) {
            defaultSelectedSubject = new Map().set(defaultSubject, nrOfDefaultSubjects);
        }
        try {
            baseFilter = getFilter(options, context, preSelectedAmenities!);
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.error("Distribution channel is not defined for search facet widget");
        }

        if (baseFilter) {
            return (
                <SearchFacet
                    className={className}
                    context={context}
                    distributionChannelId={options.distributionChannelId}
                    baseUri={baseUri}
                    title={localContent && localContent.facetTitle ? localContent.facetTitle : options.facetTitle}
                    dateRangePicker={options.dateRangePicker}
                    baseFilter={baseFilter}
                    multiAccoKindSelector={options.multiAccoKindSelector}
                    multiResortSelector={options.multiResortSelector}
                    searchButton={true}
                    showDateStayPopup={options.showDateStayPopup}
                    hideWidgetOptions={options}
                    redirectBookingsEngine={options.redirectBookingsEngine}
                    useResortForSubjects={options.useResortForSubjects}
                    resortId={options.resortId}
                    selectedAmenityCategories={this.props.selectedAmenityCategories}
                    defaultSelectedSubjects={defaultSelectedSubject}
                    showStartEndDateLabel={options.showStartEndDateLabel}
                    dateFormat={this.props.options.dateFormat}
                />
            );
        }
        return <div />;
    }
}

function getFilter(widgetOptions: WidgetOptions, context: any, amenities?: number[]): MXTS.AvailabilityRequest {
    const { distributionChannelId } = widgetOptions;

    if (distributionChannelId == null) {
        throw new Error("No distribution channel given");
    }
    const options = parse(context.location.search);
    const aggregations: MXTS.Aggregation[] = [
        {
            name: "ACCOMMODATIONKIND_FACET",
            field: "ACCOMMODATIONKIND_ID",
            excludeFields: ["ACCOMMODATIONKIND_ID"],
            type: "FACET",
            size: 1000,
        },
        {
            name: "ARRIVAL_DATE_FACET",
            field: "ARRIVAL_DATE",
            type: "FACET",
            excludeFields: ["ARRIVAL_DATE", "DURATION"],
            size: 1000,
        },
        {
            name: "ALL_AMENITIES_FACET",
            field: "ALL_AMENITIES",
            type: "FACET",
            size: 1000,
        },
        {
            name: "DURATION_FACET",
            field: "DURATION",
            type: "FACET",
            excludeFields: ["DURATION"],
            size: 1000,
        },
    ];
    if (distributionChannelId === 420041 || distributionChannelId === 3079244 || distributionChannelId === 3133244) {
        aggregations.push({
            name: "RESORT_FACET",
            field: "RESORT_ID",
            excludeFields: ["RESORT_ID"],
            type: "FACET",
            size: 1000,
        });
    }
    options.releaseStatus = "OPEN";
    options.amenities = amenities;
    const baseFilter = getBaseFilter({
        options,
        distributionChannelId,
        customAggregations: aggregations,
        fetchUnitsWithPrice: "false",
    });
    return baseFilter;
}
